<div class="container text-center">
    <div class="row mt-5">
        <div class="col-md-4">
            <label for="">Terms And Conditions : </label>
        </div>
        <div class="col-md-5">
            <input type="text" placeholder="Enter Terms and Conditions URL" class="form-control" name="conditionsUrl"> <!-- [(ngModel)]="legalData.conditionsUrl" -->
        </div>
        <div class="col-md-1">
            <a href="javascript:void(0)" target="_blank"><i class="fas fa-external-link-alt" style="font-size: 24px;margin-top: 5px;"></i></a>
            <!-- {{legalData.conditionsUrl}} -->
        </div>
        <div class="col-md-2">
            <button (click)="updatePolicyURL(0)" class="btn btn-block" style="background-color: #11734A; color: #fff;">Update URL</button>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-4">
            <label for="">Privacy Policy : </label>
        </div>
        <div class="col-md-5">
            <input type="text" placeholder="Enter Privacy Policy URL" class="form-control" name="policyUrl"> <!--  [(ngModel)]="legalData.policyUrl" -->
        </div>
        <div class="col-md-1">
            <a href="javascript:void(0)" target="_blank"><i class="fas fa-external-link-alt" style="font-size: 24px;margin-top: 5px;"></i></a>
            <!-- {{legalData.policyUrl}} -->
        </div>
        <div class="col-md-2">
            <button (click)="updatePolicyURL(1)" class="btn btn-block" style="background-color: #11734A; color: #fff;">Update URL</button>
        </div>
    </div>
</div>