import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContentDataServiceService } from 'src/app/services/content-data-service.service';

@Component({
  selector: 'app-create-waitlist-user',
  templateUrl: './create-waitlist-user.component.html',
  styleUrls: ['./create-waitlist-user.component.css']
})
export class CreateWaitlistUserComponent implements OnInit {

  waitlistForm: FormGroup;
  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateWaitlistUserComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private contentDataService: ContentDataServiceService
  ) {
    this.waitlistForm = this.fb.group({
      name: ['', Validators.required],
      user_email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.spinner.show();
    this.formSubmitted = true;
    if (this.waitlistForm.invalid) {
      this.spinner.hide();
      return;
    }

    this.closeModel();
    this.sendMail(this.waitlistForm.value);
  }

  sendMail(user) {
    let payload = {
      user_email: user.user_email,
      name: user.name
    };

    this.contentDataService.sendMail(payload).subscribe((res: any) => {
      if (res.status) {
        this.toast.success(res.description);
      }
    }, err => { });
  }

  closeModel() {
    this.dialogRef.close();
  }

}
