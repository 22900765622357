<div class="container">
    <h5>Select a color palette</h5>
    <hr/>
    <ul style="padding: 0;">
        <li style="list-style:none;cursor: pointer;">
            <div *ngFor="let item of colorPalette; let i = index" style="display: flex; justify-content: center;" (click)="onSelect(item)">
                <div style="color: #fff;">{{ i + 1 }}</div>
                <div *ngFor="let o of item.options" [ngStyle]="{'background-color': o.colorCode}" style="width: 100px; height: 50px; margin-bottom: 16px"></div>
            </div>
        </li>
    </ul>
</div>
