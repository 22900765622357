<div *ngIf="isLoggedIn">
  <div class="d-flex" id="wrapper">
    <!-- Sidebar -->
    <div style="border-right: 2px solid #d3d3d3;" id="sidebar-wrapper">
      <div class="sidebar-heading text-center my-lg-1"
        style="font-family: Poly;color: #11734A;font-weight: 600;font-size: 19px;">
        Tenacious Tracking
      </div>
      <div class="list-group list-group-flush mt-lg-2 mySideBar" style="overflow-y: auto;height: 688px;">
        <a routerLink="/admin/dashboard" routerLinkActive='selected'
          style="font-size: 17px;width: 100%;padding:13px 0 13px 13px">
          <img src="../assets/svgs/dashboard.svg" alt="" width="28px">&nbsp; Dashboard</a>

        <a routerLink="/admin/users" routerLinkActive='selected'
          style="font-size: 17px;width: 100%;padding:13px 0 13px 13px"><img src="../assets/svgs/user1.svg" alt=""
            width="28px"> &nbsp;User</a>

        <a routerLink="/admin/admin-recommended/outcomes" style="font-size: 17px;width: 100%;padding:13px 0 13px 13px">
          <img src="../assets/svgs/admin.svg" alt="" width="28px"> &nbsp;Admin Recommended</a>
        <ul class="submenu">
          <li style="font-size: 17px;width: 100%;padding:13px 0 13px 13px"
            routerLink="/admin/admin-recommended/outcomes" routerLinkActive='selected'><a class="pending"><img
                src="../assets/svgs/outcome.svg" alt="" width="24px">&nbsp;Outcomes</a>
          </li>
          <li style="font-size: 17px;width: 100%;padding:13px 0 13px 13px"
            routerLink="/admin/admin-recommended/influences" routerLinkActive='selected'><a class="approved"><img
                src="../assets/svgs/influence.svg" alt="" width="24px">&nbsp;Influences</a>
          </li>
          <li style="font-size: 17px;width: 100%;padding:13px 0 13px 13px"
            routerLink="/admin/admin-recommended/reflective-questions" routerLinkActive='selected'><a
              class="rejected"><img src="../assets/svgs/reflective.svg" alt="" width="24px">&nbsp;Reflective
              Questions</a>
          </li>
        </ul>

        <a routerLink="/admin/user-created/outcomes" style="font-size: 17px;width: 100%;padding:13px 0 13px 13px">
          <img src="../assets/svgs/users.svg" alt="" width="28px"> &nbsp;User Created</a>
        <ul class="submenu">
          <li style="font-size: 17px;width: 100%;padding:13px 0 13px 13px" routerLink="/admin/user-created/outcomes"
            routerLinkActive='selected'><a class="pending"><img src="../assets/svgs/outcome.svg" alt=""
                width="24px">&nbsp;Outcomes</a>
          </li>
          <li style="font-size: 17px;width: 100%;padding:13px 0 13px 13px" routerLink="/admin/user-created/influences"
            routerLinkActive='selected'><a class="approved"><img src="../assets/svgs/influence.svg" alt=""
                width="24px">&nbsp;Influences</a>
          </li>
          <li style="font-size: 17px;width: 100%;padding:13px 0 13px 13px"
            routerLink="/admin/user-created/reflective-questions" routerLinkActive='selected'><a class="rejected"><img
                src="../assets/svgs/reflective.svg" alt="" width="24px">&nbsp;Reflective
              Questions</a>
          </li>
        </ul>

        <a routerLink="/admin/waitlist" style="font-size: 17px;width: 100%;padding:13px 0 13px 13px">
          <img src="../assets/svgs/users.svg" alt="" width="28px"> &nbsp;Waitlist</a>
          <a routerLink="/admin/track-quote" style="font-size: 17px;width: 100%;padding:13px 0 13px 13px">
            <img src="../assets/svgs/dashboard.svg" alt="" width="28px"> &nbsp;Track Quote</a>

        <a routerLink="/admin/category-recommended/admin" style="font-size: 17px;width: 100%;padding:13px 0 13px 13px">
          <img src="../assets/svgs/category.svg" alt="" width="28px"> &nbsp;Category Recommended</a>
        <ul class="submenu">
          <li style="font-size: 17px;width: 100%;padding:13px 0 13px 13px"
            routerLink="/admin/category-recommended/admin" routerLinkActive='selected'><a class="pending"><img
                src="../assets/svgs/admin1.svg" alt="" width="24px"> &nbsp;Admin</a>
          </li>
          <li style="font-size: 17px;width: 100%;padding:13px 0 13px 13px" routerLink="/admin/category-recommended/user"
            routerLinkActive='selected'><a class="approved"><img src="../assets/svgs/user12.svg" alt="" width="24px">
              &nbsp;User</a>
          </li>
        </ul>

        <a routerLink="/admin/all-reports" routerLinkActive='selected'
          style="font-size: 17px;width: 100%;padding:13px 0 13px 13px"><img src="../assets/svgs/admin1.svg" alt=""
            width="28px"> &nbsp;Bug report management</a>

        <a routerLink="/admin/all-feedbacks" routerLinkActive='selected'
          style="font-size: 17px;width: 100%;padding:13px 0 13px 13px"><img src="../assets/svgs/influence.svg" alt=""
            width="28px"> &nbsp;Feedbacks</a>

        <a routerLink="/admin/all-features" routerLinkActive='selected'
          style="font-size: 17px;width: 100%;padding:13px 0 13px 13px"><img src="../assets/svgs/influence.svg" alt=""
            width="28px"> &nbsp;Features</a>

        <!-- <a routerLink="/admin/send-notifications" routerLinkActive='selected'
          style="font-size: 17px;width: 100%;padding:13px 0 13px 13px">
          <img src="../assets/svgs/notification.svg" alt="" width="28px"> &nbsp;Notifications</a> -->

        <a routerLink="/admin/policy-management" routerLinkActive='selected'
          style="font-size: 17px;width: 100%;padding:13px 0 13px 13px">
          <img src="../assets/svgs/policy.svg" alt="" width="28px"> &nbsp;Policy Management</a>

      </div>
    </div>
    <!-- /#sidebar-wrapper -->
    <!-- Page Content -->
    <div id="page-content-wrapper">
      <nav class="navbar navbar-expand-lg navbar-light bg-light1" style="border-bottom: 2px solid lightgray;">

        <div class="text-center pl-lg-5 py-lg-2">
          <!-- <h2 class="myText">Admin Panel</h2> -->
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="toggleNavbar = !toggleNavbar"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li class="nav-item active">
              <!-- <a class="nav-link" style="font-weight: 600;" routerLink="/admin/dashboard">Home <span class="sr-only">(current)</span></a> -->
            </li>

            <li class="nav-item dropdown">
              <a *ngIf="adminId!=undefined" style="font-weight:600;color:black;" class="nav-link dropdown-toggle"
                href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                {{name | titlecase}} <img [src]="userImage" onerror="this.src='../assets/welcomeProfile.jpg'"
                  style="border-radius:10px" height="30px" width="36px">
              </a>

              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" style="font-weight: 600;color: black;" routerLink="/admin/profile"><i
                    class="fa fa-user-circle" aria-hidden="true"></i> &nbsp; Profile </a>
                <div class="dropdown-divider"></div>
                <a (click)="logout()" class="dropdown-item" href="#" style="font-weight: 600; color: black;"><i
                    class="fa fa-info-circle" aria-hidden="true"></i> &nbsp; Logout</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div class="container-fluid">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <router-outlet></router-outlet>

              <!-- <canvas baseChart 
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas> -->
            </div>
          </div>

        </div>

      </div>
    </div>
    <!-- /#page-content-wrapper -->
  </div>
</div>
<div *ngIf="!isLoggedIn">
  <app-login-user></app-login-user>
</div>