import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColorPalette } from 'src/app/data';

@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.css']
})
export class ColorPaletteComponent implements OnInit {

  colorPalette = ColorPalette;

  constructor(
    public dialogRef: MatDialogRef<ColorPaletteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  onSelect(item){
    console.log(item)
    this.closeModel(item.options);
  }

  closeModel(item) {
    this.dialogRef.close(item);
  }

}
