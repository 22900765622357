import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllFeaturesComponent } from './components/all-features/all-features.component';
import { AllFeedbackComponent } from './components/all-feedback/all-feedback.component';
import { AllReportsComponent } from './components/all-reports/all-reports.component';
import { DashboardDataComponent } from './components/dashboard-data/dashboard-data.component';
import { LoginUserComponent } from './components/login-user/login-user.component';
import { PolicyManagementComponent } from './components/policy-management/policy-management.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SendNotificationsComponent } from './components/send-notifications/send-notifications.component';
import { OnlyLoginGuardService } from './services/only-login-guard/only-login-guard.service';
import { WaitlistUsersComponent } from './components/waitlist-users/waitlist-users.component';
import { AllQuotesComponent } from './components/all-quotes/all-quotes.component';


const routes: Routes = [
  { path: 'admin/dashboard', component: DashboardDataComponent },
  { path: 'admin/users', loadChildren: () => import(`./components/users/user.module`).then(m => m.UserModule) },
  { path: 'admin/admin-recommended', loadChildren: () => import(`./components/admin-recommended/admin-recommended.module`).then(m => m.AdminRecommendedModule) },
  { path: 'admin/user-created', loadChildren: () => import(`./components/user-recommended/user-recommended.module`).then(m => m.UserRecommendedModule) },
  { path: 'admin/category-recommended', loadChildren: () => import(`./components/category-recommended/category-recommended.module`).then(m => m.CategoryRecommendedModule) },
  { path: 'admin/send-notifications', component: SendNotificationsComponent },
  { path: 'admin/all-reports', component: AllReportsComponent },
  { path: 'admin/all-feedbacks', component: AllFeedbackComponent },
  { path: 'admin/all-features', component: AllFeaturesComponent },
  { path: 'admin/policy-management', component: PolicyManagementComponent },
  { path: 'admin/login', component: LoginUserComponent, canActivate: [OnlyLoginGuardService] },
  { path: 'admin/profile', component: ProfileComponent },
  { path: 'admin/waitlist', component: WaitlistUsersComponent },
  { path: 'admin/track-quote', component: AllQuotesComponent },

  { path: '', redirectTo: '/admin/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
