<div class="container text-center">
    <hr class="my-3">
    <!-- <div class="row">
        <div class="col-md-6">
            <label for="">Type : </label>
        </div>
        <div class="col-md-6">
            {{reportDetails.bugType}}
        </div>
    </div> -->
    <div class="row">
        <div class="col-md-6">
            <label for="">Theme : </label>
        </div>
        <div class="col-md-6">
            {{feedback.theme}}
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="">Description : </label>
        </div>
        <div class="col-md-6">
            {{feedback.description}}
        </div>
    </div>
    <!-- <div class="row mt-2">
        <div class="col-md-12">
            <img src={{reportDetails.imageURL}} onerror="this.src='../../assets/personalityTest.jpg'" height="200px" width="auto" alt="">
        </div>
    </div> -->
 
    <hr class="my-3">
</div>