<div class="wrapper" style="margin-top: 28px">
    <div class="table2" style="margin-bottom: 10px">
        <div class="row2">
            <div class="cell2 d-flex text-left" style="box-shadow: none">
                <div class="form-group">
                    <span class="fa fa-search form-control-icon"></span>
                    <input type="text" class="form-control" [(ngModel)]="filteredStatus" style="width: 300px;"
                        placeholder="Search" />
                </div>
            </div>
        </div>
    </div>

    <table class="table text-center" style="box-shadow: none">
        <thead>
            <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Type</th>
                <th scope="col">Created On</th>
                <th scope="col">View</th>
            </tr>
        </thead>
        <tbody style="color: gray; font-weight: 600" *ngIf="allReports.length">
            <tr *ngFor="let report of allReports
                | nameFilter: filteredStatus
                | paginate: { itemsPerPage:10, currentPage:p, totalItems: totalCount }; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ report.name }}</td>
                <td>{{ report.user_email }}</td>
                <td>{{ report.bugType }}</td>
                <td>{{ report.createdAt | date:'medium' }}</td>
                <td>
                    <a href="javascript:void(0)" (click)="viewSingleReport(report)">
                        <i class="fas fa-external-link-alt" aria-hidden="true" style="font-size: 17px"></i>
                    </a>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!allReports.length">
            <tr>
                <td colspan="4">
                    <h3 class="mt-2" style="color: #d6d6d6;">No data to show</h3>
                </td>
            </tr>
        </tbody>
    </table>

    <!-- pagination -->
    <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading bug report(s)...</p>
</ngx-spinner>