import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContentDataServiceService } from 'src/app/services/content-data-service.service';

@Component({
  selector: 'app-create-quote',
  templateUrl: './create-quote.component.html',
  styleUrls: ['./create-quote.component.css']
})
export class CreateQuoteComponent implements OnInit {

  quoteForm: FormGroup;
  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateQuoteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private contentDataService: ContentDataServiceService
  ) {
    this.quoteForm = this.fb.group({
      title: ['', Validators.required]
    });

    if(this.data && this.data.quote) {
      this.quoteForm.patchValue(this.data.quote);
    }
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.spinner.show();
    this.formSubmitted = true;
    if (this.quoteForm.invalid) {
      this.spinner.hide();
      return;
    }

    if(this.data && this.data.quote) {
      let payload = {
        id: this.data.quote._id,
        title: this.quoteForm.value.title
      }
      this.contentDataService.updateQuote(payload).subscribe(
        (res: any) => {
          if (res.status) {
            this.spinner.hide();
            this.toast.success(res.description);
            this.closeModel();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      this.contentDataService.createQuote(this.quoteForm.value).subscribe(
        (res: any) => {
          if (res.status) {
            this.spinner.hide();
            this.toast.success(res.description);
            this.closeModel();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  }

  closeModel() {
    this.dialogRef.close();
  }

}
