<div class="container">
    <h5>Choose an icon</h5>
    <hr />
    <ul style="display: flex;padding: 0;justify-content: center;">
        <li *ngFor="let i of icons" style="display: inline; list-style:none;cursor: pointer;" class="px-2"
            (click)="onSelect(i)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 38px; height: 38px">
                <path [attr.d]="i"></path>
            </svg>
        </li>
    </ul>
</div>