<div class="container">
    <hr />
    <form [formGroup]="waitlistForm" (ngSubmit)="onSubmit()">
        <div class="row mt-4">
            <div class="col">
                <label> Name </label>
                <input type="text" class="form-control" formControlName="name" rows="4" placeholder="Add name here" />
                <div *ngIf="(waitlistForm.controls.name.touched) && waitlistForm.controls.name.errors?.required"
                    class="error">
                    *Name is required!
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <label> Email </label>
                <input type="email" class="form-control" formControlName="user_email" rows="4"
                    placeholder="Add email here" />
                <div *ngIf="(waitlistForm.controls.user_email.touched) && waitlistForm.controls.user_email.errors?.required"
                    class="error">
                    *Email is required!
                </div>
                <div *ngIf="(waitlistForm.controls.user_email.touched) && waitlistForm.controls.user_email.errors?.email"
                    class="error">
                    Enter valid email! (eg. example@gmail.com)
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12 text-center">
                <button type="submit" [disabled]="!waitlistForm.valid" style="background-color: #11734A"
                    class="btn btn-block text-white">
                    Save
                </button>
            </div>
        </div>
    </form>
    <hr />
</div>