import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-single-report',
  templateUrl: './view-single-report.component.html',
  styleUrls: ['./view-single-report.component.css']
})
export class ViewSingleReportComponent implements OnInit {

  reportDetails: any;
  previewImg: any;

  constructor(
    public dialogRef: MatDialogRef<ViewSingleReportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService
  ) {
    this.reportDetails = data.reportData;
    this.readFile(this.reportDetails.imageURL)
  }

  ngOnInit(): void {
  }

  readFile(url) {
    this.authService.readFile(url).subscribe((image: any) => {
      this.previewImg = image;
    }, error => { });
  }

}
