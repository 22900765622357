import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameFilterPipe } from '../pipes/name-filter/name-filter.pipe';
import { SelectIconComponent } from './select-icon/select-icon.component';
import { ColorPaletteComponent } from './color-palette/color-palette.component';



@NgModule({
  declarations: [NameFilterPipe, SelectIconComponent, ColorPaletteComponent],
  imports: [
    CommonModule
  ],
  exports: [NameFilterPipe]
})
export class SharedModule { }
