export const Icons = [
    'M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M12.5 7V12.2L9.8 17L8.5 16.2L11 11.8V7H12.5Z',
    'M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z',
    'M10,2C8.18,2 6.47,2.5 5,3.35C8,5.08 10,8.3 10,12C10,15.7 8,18.92 5,20.65C6.47,21.5 8.18,22 10,22A10,10 0 0,0 20,12A10,10 0 0,0 10,2Z',
    'M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z',
    'M2 13.3364C1.99969 10.8887 3.77168 8.80077 6.18683 8.40302C7.52395 6.02105 10.179 4.69598 12.8863 5.05951C15.5937 5.42304 17.8051 7.40159 18.4665 10.0519C20.6196 10.3828 22.1543 12.3164 21.9876 14.4884C21.8209 16.6603 20.0091 18.3372 17.8308 18.3356H6.9992C4.23822 18.3356 2 16.0974 2 13.3364Z',
    'M13.16,12.74L14,14H12.5C12.35,16.71 12,19.41 11.5,22.08L10.5,21.92C11,19.3 11.34,16.66 11.5,14H10L10.84,12.74C8.64,11.79 7,8.36 7,6A5,5 0 0,1 12,1A5,5 0 0,1 17,6C17,8.36 15.36,11.79 13.16,12.74Z',
    'M1.2234 2.21444C1.18666 2.32606 1.16422 2.43218 1.14944 2.52602C1.11533 2.74261 1.10915 2.99405 1.104 3.20343L1.07471 4.3645C0.994408 7.54599 0.931247 10.0482 1.14861 12.0002C1.37146 14.0013 1.90446 15.5986 3.15291 16.8471C4.40137 18.0955 5.99871 18.6285 7.99983 18.8514C9.95179 19.0688 12.454 19.0056 15.6355 18.9253L16.7965 18.896C17.0059 18.8909 17.2574 18.8847 17.474 18.8506C17.7365 18.8092 18.0951 18.7079 18.4015 18.4015C18.7079 18.0951 18.8092 17.7365 18.8506 17.474C18.8847 17.2574 18.8909 17.0059 18.896 16.7965L18.9253 15.6355C19.0056 12.454 19.0688 9.95179 18.8514 7.99983C18.6285 5.99871 18.0955 4.40137 16.8471 3.15291C15.5986 1.90446 14.0013 1.37146 12.0002 1.14861C10.0482 0.931248 7.54602 0.994407 4.36455 1.07471L3.271 1.10229C3.53505 1.26777 3.8038 1.4425 4.07478 1.62455C5.83262 2.80552 7.75984 4.34562 9.20711 5.79289C9.24689 5.83267 9.28647 5.87257 9.32586 5.91257L12.6049 5.03395C13.1384 4.89101 13.6867 5.20759 13.8296 5.74106C13.9726 6.27453 13.656 6.82286 13.1225 6.96581L10.8241 7.58166C11.5616 8.47714 12.1901 9.35173 12.6863 10.0918C13.1017 10.7114 13.428 11.2421 13.6514 11.6197C13.7632 11.8087 13.8495 11.9596 13.9084 12.0646C13.9379 12.1171 13.9606 12.1582 13.9763 12.1868L13.9945 12.2202L13.9997 12.2297L14.0012 12.2326L14.0017 12.2335C14.2627 12.7203 14.08 13.3271 13.5933 13.588C13.1067 13.8489 12.5007 13.6661 12.2396 13.1796L12.2363 13.1734L12.2227 13.1485C12.2102 13.1256 12.1908 13.0905 12.1647 13.0441C12.1125 12.9512 12.0338 12.8133 11.9301 12.6381C11.7228 12.2876 11.4165 11.7893 11.0252 11.2056C10.3586 10.2114 9.45527 8.98487 8.38334 7.82246C8.30249 7.75923 8.231 7.68335 8.17226 7.59675C8.04789 7.46564 7.9214 7.33562 7.79289 7.20711C7.54389 6.9581 7.27823 6.70497 7 6.45064V10.5C7 11.0523 6.55228 11.5 6 11.5C5.44772 11.5 5 11.0523 5 10.5V4.76696C4.32256 4.23824 3.62876 3.73435 2.95946 3.28469C2.33882 2.86773 1.74969 2.50447 1.2234 2.21444Z',
    'M3.0124 11.4782L9.42661 17.4648L9.42663 17.4649C9.64511 17.6688 9.75435 17.7707 9.88126 17.8018C9.95927 17.8208 10.0407 17.8208 10.1187 17.8018C10.2456 17.7707 10.3549 17.6688 10.5734 17.4649L10.5734 17.4648L16.9876 11.4782C18.7233 9.85823 19.0255 7.21748 17.7007 5.2472L17.4445 4.8662C15.7015 2.274 11.8006 2.56059 10.4552 5.37968C10.2725 5.76249 9.72755 5.76249 9.54485 5.37968C8.19937 2.56059 4.29852 2.274 2.55549 4.86619L2.2993 5.2472C0.974454 7.21748 1.27667 9.85823 3.0124 11.4782Z'
];

export const ColorPalette = [
    {
        id: 1,
        options: [
            {
                text: "very low",
                colorCode: "#F17F79"
            },
            {
                text: "low",
                colorCode: "#FEB266"
            },
            {
                text: "medium",
                colorCode: "#F5DB87"
            },
            {
                text: "high",
                colorCode: "#68D7A8"
            },
            {
                text: "very high",
                colorCode: "#169C63",
            }
        ]
    }, {
        id: 2,
        options: [
            {
                text: "very low",
                colorCode: "#169C63"
            },
            {
                text: "low",
                colorCode: "#68D7A8"
            },
            {
                text: "medium",
                colorCode: "#F5DB87"
            },
            {
                text: "high",
                colorCode: "#FEB266"
            },
            {
                text: "very high",
                colorCode: "#F17F79"
            }
        ]
    }, {
        id: 3,
        options: [
            {
                text: "very low",
                colorCode: "#ef5350"
            },
            {
                text: "low",
                colorCode: "#d32f2f"
            },
            {
                text: "medium",
                colorCode: "#c62828"
            },
            {
                text: "high",
                colorCode: "#991616"
            },
            {
                text: "very high",
                colorCode: "#5a0b0b"
            }
        ]
    }, {
        id: 4,
        options: [
            {
                text: "very low",
                colorCode: "#ff9800"
            },
            {
                text: "low",
                colorCode: "#ed6c02"
            },
            {
                text: "medium",
                colorCode: "#e65100"
            },
            {
                text: "high",
                colorCode: "#974316"
            },
            {
                text: "very high",
                colorCode: "#9b5f3f"
            }
        ]
    }, {
        id: 5,
        options: [
            {
                text: "very low",
                colorCode: "#4caf50"
            },
            {
                text: "low",
                colorCode: "#2e7d32"
            },
            {
                text: "medium",
                colorCode: "#1b5e20"
            },
            {
                text: "high",
                colorCode: "#04500a"
            },
            {
                text: "very high",
                colorCode: "#103813"
            }
        ]
    }, {
        id: 6,
        options: [
            {
                text: "very low",
                colorCode: "#42a5f5",
            },
            {
                text: "low",
                colorCode: "#1976d2",
            },
            {
                text: "medium",
                colorCode: "#1565c0",
            },
            {
                text: "high",
                colorCode: "#0751a5",
            },
            {
                text: "very high",
                colorCode: "#09315e",
            }
        ]
    }, {
        id: 7,
        options: [
            {
                text: "very low",
                colorCode: "#ba68c8"
            },
            {
                text: "low",
                colorCode: "#9c27b0"
            },
            {
                text: "medium",
                colorCode: "#7b1fa2"
            },
            {
                text: "high",
                colorCode: "#581076"
            },
            {
                text: "very high",
                colorCode: "#37054c"
            }
        ]
    }
];

export const InfluenceTypes = [
    {name: 'Numeric value based on unit of measurement', value: 'TYPE1'},
    {name: 'Scale based on values of 1 to 5', value: 'TYPE2'},
    {name: 'Simply based on a yes and no basis', value: 'TYPE3'}
];