<div class="row mt-4">
  <div class="col-md-3 mb-3">
    <div class="card text-center o-hidden h-100">
      <div class="card-body">
        <p class="countStyle">{{ totalUsers }}</p>
        <p class="tagStyle"><i class="fas fa-user-circle"></i> Total Users</p>
      </div>
    </div>
  </div>
  <div class="col-md-3 mb-3">
    <div class="card text-center o-hidden h-100">
      <div class="card-body">
        <p class="countStyle">{{ totalBeginners }}</p>
        <p class="tagStyle"><i class="fas fa-user-circle"></i> Total Beginner Users</p>
      </div>
    </div>
  </div>
  <div class="col-md-3 mb-3">
    <div class="card text-center o-hidden h-100">
      <div class="card-body">
        <p class="countStyle">{{ totalAdvanced }}</p>
        <p class="tagStyle"><i class="fas fa-user-circle"></i> Total Advanced Users</p>
      </div>
    </div>
  </div>
  <div class="col-md-3 mb-3">
    <div class="card text-center o-hidden h-100">
      <div class="card-body">
        <p class="countStyle">{{ totalOutcomes }}</p>
        <p class="tagStyle"><i class="fas fa-user-circle"></i> Total Outcomes</p>
      </div>
    </div>
  </div>
  <div class="col-md-3 mb-3">
    <div class="card text-center o-hidden h-100">
      <div class="card-body">
        <p class="countStyle">{{ totalInfluences }}</p>
        <p class="tagStyle"><i class="fas fa-user-circle"></i> Total Influences</p>
      </div>
    </div>
  </div>
  <div class="col-md-3 mb-3">
    <div class="card text-center o-hidden h-100">
      <div class="card-body">
        <p class="countStyle">{{ totalReflectiveQuestions }}</p>
        <p class="tagStyle"><i class="fas fa-user-circle"></i> Total Reflective Questions</p>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row mt-4">
    <div class="col-md-6">
        <canvas baseChart 
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [colors]="barChartColors"
            [chartType]="barChartType">
        </canvas>
    </div>
    <div class="col-md-6"></div>
</div> -->

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>