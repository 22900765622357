<div class="container text-center">
    <hr class="my-3">
    <div class="row">
        <div class="col-md-6">
            <label for="">Feature type : </label>
        </div>
        <div class="col-md-6">
            {{feature.featureType}}
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="">Description : </label>
        </div>
        <div class="col-md-6">
            {{feature.description}}
        </div>
    </div>
    <!-- <div class="row mt-2">
        <div class="col-md-12">
            <img src={{feature.imageURL}} onerror="this.src='../../assets/personalityTest.jpg'" height="200px" width="auto" alt="">
        </div>
    </div> -->
 
    <hr class="my-3">
</div>