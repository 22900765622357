import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateWaitlistUserComponent } from '../create-waitlist-user/create-waitlist-user.component';
import { ContentDataServiceService } from 'src/app/services/content-data-service.service';

@Component({
  selector: 'app-waitlist-users',
  templateUrl: './waitlist-users.component.html',
  styleUrls: ['./waitlist-users.component.css']
})
export class WaitlistUsersComponent implements OnInit {
  waitlistUsers = [];
  filteredStatus = '';

  constructor(
    private contentDataService: ContentDataServiceService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    public matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getWaitlist();
  }

  getWaitlist() {
    this.spinner.show();
    this.contentDataService.getWaitlist({}).subscribe((res: any) => {
      this.waitlistUsers = res.data;
      this.spinner.hide();
    }, err => { });
  }

  addUser() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'save-waitlist-user';
    dialogConfig.height = 'auto';
    dialogConfig.width = '500px';
    //passing data
    // dialogConfig.data = { bookingNumber: this.bookingNumber };

    this.matDialog.open(CreateWaitlistUserComponent, dialogConfig);
    this.matDialog
      ._getAfterAllClosed()
      .subscribe((res: any) => setTimeout(() => this.getWaitlist(), 1000));
  }

  onSend(user) {
    let payload = {
      user_email: user.user_email,
      name: user.name
    };

    this.contentDataService.sendMail(payload).subscribe((res: any) => {
      if (res.status) {
        this.toast.success(res.description);
        this.getWaitlist();
      }
    }, err => { });
  }

}
