import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContentdataService } from 'src/app/services/contentdata.service';
import { ViewSingleFeedbackComponent } from '../view-single-feedback/view-single-feedback.component';

@Component({
  selector: 'app-all-feedback',
  templateUrl: './all-feedback.component.html',
  styleUrls: ['./all-feedback.component.css']
})
export class AllFeedbackComponent implements OnInit {
  allFeedbacks = [];
  filteredStatus = '';

  p: number = 1;
  totalCount: number;

  constructor(
    public matDialog: MatDialog,
    private contentDataService: ContentdataService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
      this.getAllFeedbacks(0);
  }

  getAllFeedbacks(skip) {
    this.spinner.show();
    let payload = {
      skip,
      limit: 10
    }

    this.contentDataService
      .getAllFeedbacks(payload)
      .subscribe((res) => {
        this.allFeedbacks = res['feedbackData'];
        this.totalCount = res['feedbackTotal']
        this.spinner.hide();
      });
  }

  onPageChange(e) {
    this.p = e;
    let skip = e == 1 ? 0 : (e - 1) * 10;
    this.getAllFeedbacks(skip)
  }

  viewSingleFeedback(feedback: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'view-single-report-component';
    dialogConfig.height = "auto";
    dialogConfig.width = '460px';
    //passing data
    dialogConfig.data = { feedback };

    const modalDialog = this.matDialog.open(
      ViewSingleFeedbackComponent,
      dialogConfig
    );
  }

}
