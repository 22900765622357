
<div class="wrapper" style="margin-top: 28px">
    <div class="table2" style="margin-bottom: 10px">
        <div class="row2">
            <div class="cell2 d-flex text-left" style="box-shadow: none">
                <div class="form-group">
                    <span class="fa fa-search form-control-icon"></span>
                    <input type="text" class="form-control" [(ngModel)]="filteredStatus" style="width: 300px;"
                        placeholder="Search" />
                </div>
            </div>
            <div class="cell2 myCell pl-lg-2 mt-2 text-right">
                <button (click)="addQuote()"
                    style="background-color: #11734A; border-radius: 8px; color: white" class="btn mb-1">
                    <i class="fas fa-plus"></i> Add Quote
                </button>
            </div>
        </div>
    </div>

    <table class="table text-center" style="box-shadow: none">
        <thead>
            <tr>
                <th scope="col">S.No</th>
                <th scope="col">Quote</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
            </tr>
        </thead>
        <tbody style="color: gray; font-weight: 600" *ngIf="quotes.length">
            <tr *ngFor="let q of quotes
                | nameFilter: filteredStatus; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ q.title }}</td>
                <td>
                    <a href="javascript:void(0)" (click)="editQuote(q)">
                        <i class="far fa-edit" aria-hidden="true" style="font-size: 17px"></i>
                    </a>
                </td>
                <td>
                    <a href="javascript:void(0)" (click)="onDelete(q)" role="button" data-toggle="modal"
                        data-target="#exampleModalCenter">
                        <i class="fas fa-trash-alt" aria-hidden="true" style="font-size: 17px"></i>
                    </a>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!quotes.length">
            <tr>
                <td colspan="5">
                    <h3 class="mt-2" style="color: #d6d6d6;">No data to show</h3>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading quote(s)...</p>
</ngx-spinner>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to delete it?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-danger" (click)="deleteQuote();"
                    data-dismiss="modal">Delete</button>
            </div>
        </div>
    </div>
</div>