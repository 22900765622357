import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentDataServiceService {
  apiPrefix: any;
  headers = new HttpHeaders();

  constructor(
    private http: HttpClient
  ) {
    this.apiPrefix = environment.baseUrl + 'api/v1/';
    this.headers = this.headers.set(
      'x-access-token',
      localStorage.getItem('adminAuthTokenTNT')
    );
  }

  getWaitlist(data: any) {
    return this.http.post(this.apiPrefix + 'wait-list/list', data, {
      headers: this.headers,
    });
  }

  sendMail(data: any) {
    return this.http.post(this.apiPrefix + 'wait-list/send', data, {
      headers: this.headers,
    });
  }

  getAllQuotes(data) {
    return this.http.post(this.apiPrefix + 'track-quote/list', data, {
      headers: this.headers,
    });
  }

  deleteQuote(data) {
    return this.http.post(this.apiPrefix + 'track-quote/delete', data, {
      headers: this.headers,
    });
  }

  createQuote(data) {
    return this.http.post(this.apiPrefix + 'track-quote/create', data, {
      headers: this.headers,
    });
  }

  updateQuote(data) {
    return this.http.post(this.apiPrefix + 'track-quote/update', data, {
      headers: this.headers,
    });
  }


}
