import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-single-feature',
  templateUrl: './view-single-feature.component.html',
  styleUrls: ['./view-single-feature.component.css']
})
export class ViewSingleFeatureComponent implements OnInit {
  feature: any;

  constructor(
    public dialogRef: MatDialogRef<ViewSingleFeatureComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.feature = data.feature;
    // this.feature.imageURL = environment.baseURL + "app/download" + this.reportDetails.imageURL;
  }

  ngOnInit(): void {
  }

}
