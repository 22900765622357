import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-policy-management',
  templateUrl: './policy-management.component.html',
  styleUrls: ['./policy-management.component.css']
})
export class PolicyManagementComponent implements OnInit {
  legalData;

  constructor(
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  updatePolicyURL(e) {}

}
