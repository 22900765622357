<div class="container">
    <hr />
    <form [formGroup]="quoteForm" (ngSubmit)="onSubmit()">
        <div class="row mt-4">
            <div class="col">
                <label> Title </label>
                <textarea class="form-control" formControlName="title" rows="4" placeholder="Add title here"></textarea>
                <div *ngIf="(quoteForm.controls.title.touched) && quoteForm.controls.title.errors?.required"
                    class="error">
                    *Title is required!
                </div>
            </div>
        </div>
        
        <div class="row mt-4">
            <div class="col-md-12 text-center">
                <button type="submit" [disabled]="!quoteForm.valid" style="background-color: #11734A"
                    class="btn btn-block text-white">
                    Save
                </button>
            </div>
        </div>
    </form>
    <hr />
</div>