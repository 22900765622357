import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  name = '';
  email = '';
  adminId;
  userImage: any;

  uploadText;

  constructor(
    public matDialog: MatDialog,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private cdRef: ChangeDetectorRef
  ) {
    this.authService.listen().subscribe((m: any) => {
      this.ngOnInit();
    });
  }

  async ngOnInit(): Promise<void> {
    this.uploadText = "Loading Profile";
    this.spinner.show();

    this.adminId = JSON.parse(localStorage.getItem('authUserDataTNT'))._id;
    // console.log(this.adminId);
    this.name = JSON.parse(localStorage.getItem('authUserDataTNT')).name;
    this.email = JSON.parse(localStorage.getItem('authUserDataTNT')).user_email;
    // this.userImage = "https://personalityapp.herokuapp.com/api/v1/app/download" + JSON.parse(localStorage.getItem('authUserDataTNT')).adminImage;
    this.userImage = this.readFile(JSON.parse(localStorage.getItem('authUserDataTNT')).adminImage);

    this.spinner.hide();
    this.cdRef.detectChanges();

  }

  selectImage(event) {
    this.uploadText = "Saving Image";

    const file = event.target.files[0];
    this.userImage = file;

    this.spinner.show();

    const formData = new FormData();
    formData.append("photo", this.userImage);
    formData.append("name", this.name);
    formData.append("email", this.email);
    formData.append("id", this.adminId);

    this.authService.editAdminImage(formData).subscribe((resAdmin: any) => {
      if (resAdmin.status) {
        this.userImage = this.readFile(resAdmin.data.adminImage);
        this.spinner.hide();
        this.toast.success('Profile picture updated successfully.');
        localStorage.setItem('authUserDataTNT', JSON.stringify(resAdmin.data))
        this.authService.loggedIn.next(true);
      } else {
        this.spinner.hide();
        this.toast.error(resAdmin.message);
      }
    });
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    //if the user clicks outside the modal, it doesn’t close
    dialogConfig.disableClose = true;
    dialogConfig.id = 'edit-admin-component';
    dialogConfig.height = "550px";
    dialogConfig.width = "1000px";

    //passing data
    console.log(this.email)
    dialogConfig.data = { adminEmail: this.email };

    const modalDialog = this.matDialog.open(EditProfileComponent, dialogConfig);
  }

  editPasswordDialog() {
    const dialogConfig = new MatDialogConfig();

    //if the user clicks outside the modal, it doesn’t close
    dialogConfig.disableClose = true;
    dialogConfig.id = 'edit-admin-password-component';
    dialogConfig.height = "430px";
    dialogConfig.width = "850px";

    //passing data
    dialogConfig.data = { adminEmail: this.email };

    const modalDialog = this.matDialog.open(EditPasswordComponent, dialogConfig);
  }

  readFile(url) {
    this.authService.readFile(url).subscribe((image: any) => {
      this.userImage = image;
    }, error => { });
  }
}
