import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AsyncPipe } from '@angular/common';
import { LoginUserComponent } from './components/login-user/login-user.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { SendNotificationsComponent } from './components/send-notifications/send-notifications.component';
import { ChartsModule } from 'ng2-charts';
import { DashboardDataComponent } from './components/dashboard-data/dashboard-data.component';
import { AppRoutingModule } from './app-routing.module';
import { OnlyLoginGuardService } from './services/only-login-guard/only-login-guard.service';
import { ProfileModule } from './components/profile/profile.module';
import { QuillModule } from 'ngx-quill';
import { PolicyManagementComponent } from './components/policy-management/policy-management.component';
import { AllReportsComponent } from './components/all-reports/all-reports.component';
import { AllFeedbackComponent } from './components/all-feedback/all-feedback.component';
import { AllFeaturesComponent } from './components/all-features/all-features.component';
import { ViewSingleReportComponent } from './components/view-single-report/view-single-report.component';
import { ViewSingleFeedbackComponent } from './components/view-single-feedback/view-single-feedback.component';
import { ViewSingleFeatureComponent } from './components/view-single-feature/view-single-feature.component';
import { SharedModule } from "./shared/shared.module";
import { WaitlistUsersComponent } from './components/waitlist-users/waitlist-users.component';
import { CreateWaitlistUserComponent } from './components/create-waitlist-user/create-waitlist-user.component';
import { AllQuotesComponent } from './components/all-quotes/all-quotes.component';
import { CreateQuoteComponent } from './components/create-quote/create-quote.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginUserComponent,
        DashboardDataComponent,
        SendNotificationsComponent,
        PolicyManagementComponent,
        AllReportsComponent,
        AllFeedbackComponent,
        AllFeaturesComponent,
        ViewSingleReportComponent,
        ViewSingleFeedbackComponent,
        ViewSingleFeatureComponent,
        WaitlistUsersComponent,
        CreateWaitlistUserComponent,
        AllQuotesComponent,
        CreateQuoteComponent
    ],
    providers: [AsyncPipe, OnlyLoginGuardService, { provide: LocationStrategy, useClass: HashLocationStrategy }],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        NgxImageZoomModule,
        MatButtonModule,
        MatDialogModule,
        MatSlideToggleModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ChartsModule,
        Ng2SearchPipeModule,
        Ng2OrderModule,
        NgxPaginationModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        CommonModule,
        PdfViewerModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        ProfileModule,
        ToastrModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        QuillModule.forRoot(),
        SharedModule
    ]
})
export class AppModule { }
