import { Component, Inject, OnInit, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileComponent } from '../profile.component';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  adminEmail;
  admin: any;
  showPassword: boolean;

  constructor(
    private authService: AuthService,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<ProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.adminEmail = data.adminEmail;
  }

  ngOnInit(): void {
    this.showPassword = false;
    this.authService.adminDetails({ email: this.adminEmail }).subscribe((res: any) => {
      this.admin = res.data;
    })
  }

  onSubmit(form: NgForm) {
    const params = { ...form.value, id: this.admin._id };
    this.authService.editAdminDetails(params).subscribe((res:any) => {
      if (res.status) {
        localStorage.setItem('authUserDataTNT', JSON.stringify(res.data))
        this.authService.loggedIn.next(true);

        // alert("Client Details Updated");
        this.toast.success("Admin Details Updated", "Successs", {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right'
        });

        this.closeModel();
      } else {
        this.toast.error("Details not updated....error occured", "Error", {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right'
        })
      }
    })

  }

  password() {
    this.showPassword = !this.showPassword;
  }

  closeModel() {
    this.dialogRef.close();
    this.authService.filter("Deleted");
  }

}
